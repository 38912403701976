var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c("v-card-title", [
        _c("span", { staticClass: "primary--text font-weight-bold" }, [
          _vm._v("Add New Application")
        ])
      ]),
      _vm.imageList.length && _vm.applicationImageFetching === false
        ? _c(
            "div",
            { staticClass: "applications-available" },
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      model: {
                        value: _vm.valid,
                        callback: function($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid"
                      }
                    },
                    [
                      _c("h4", { staticClass: "mt-4 primary--text" }, [
                        _vm._v("Select an application")
                      ]),
                      _c(
                        "v-list",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-list-item-group",
                            {
                              attrs: { "min-width": "100", color: "secondary" },
                              model: {
                                value: _vm.selectedAppType,
                                callback: function($$v) {
                                  _vm.selectedAppType = $$v
                                },
                                expression: "selectedAppType"
                              }
                            },
                            [
                              _c(
                                "v-row",
                                _vm._l(_vm.availableApps, function(app, index) {
                                  return _c(
                                    "v-col",
                                    {
                                      key: index,
                                      staticClass: "mb-1",
                                      attrs: {
                                        cols: "4",
                                        sm: "12",
                                        md: "6",
                                        lg: "4"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-item",
                                        { staticStyle: { height: "80px" } },
                                        [
                                          _c("v-img", {
                                            staticClass: "mr-4",
                                            attrs: {
                                              src: _vm.getAppLink(
                                                app.short_name
                                              ),
                                              "max-width": "50",
                                              "min-width": "50"
                                            }
                                          }),
                                          _c("v-list-item-content", [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "font-weight-bold title primary--text"
                                              },
                                              [_vm._v(_vm._s(app.name))]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "primary--text" },
                                              [_vm._v(_vm._s(app.description))]
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.showAppStartTimeWarning === true
                        ? _c(
                            "v-alert",
                            {
                              attrs: { prominent: "", type: "info", text: "" }
                            },
                            [
                              _c("div", { staticClass: "d-flex flex-column" }, [
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [_vm._v("App initial startup time")]
                                ),
                                _vm._v(
                                  " Please note that the app you have selected might take longer when started for the first time. Subsequent starts will be faster. "
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      _c("h4", { staticClass: "mt-4 primary--text" }, [
                        _vm._v("Select a version for your application")
                      ]),
                      _c("v-select", {
                        attrs: {
                          items: _vm.appVersions,
                          "item-value": "imid",
                          "item-text": "description",
                          label: "Click here to select an application",
                          required: "",
                          rules: [_vm.rules.itemSelected]
                        },
                        model: {
                          value: _vm.selectedImage,
                          callback: function($$v) {
                            _vm.selectedImage = $$v
                          },
                          expression: "selectedImage"
                        }
                      }),
                      _c("h4", { staticClass: "mt-4 primary--text" }, [
                        _vm._v("Name of the application")
                      ]),
                      _c("v-text-field", {
                        attrs: { required: "", rules: [_vm.rules.nonEmpty] },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function() {
                                return [
                                  _vm._v("Provide an application name here")
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          2385565856
                        ),
                        model: {
                          value: _vm.appName,
                          callback: function($$v) {
                            _vm.appName = $$v
                          },
                          expression: "appName"
                        }
                      }),
                      _c("v-checkbox", {
                        attrs: { label: "Add application description" },
                        model: {
                          value: _vm.addDescription,
                          callback: function($$v) {
                            _vm.addDescription = $$v
                          },
                          expression: "addDescription"
                        }
                      }),
                      _vm.addDescription
                        ? _c(
                            "div",
                            [
                              _c("h4", { staticClass: "mt-4" }, [
                                _vm._v("Description of the application")
                              ]),
                              _c("v-textarea", {
                                attrs: {
                                  label:
                                    "Provide a description of your application",
                                  rows: "4",
                                  "auto-grow": "",
                                  required: "",
                                  rules: [_vm.rules.nonEmpty]
                                },
                                model: {
                                  value: _vm.appDescription,
                                  callback: function($$v) {
                                    _vm.appDescription = $$v
                                  },
                                  expression: "appDescription"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-card-actions", { staticClass: "mt-4" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex align-center flex-column justify-center",
                    staticStyle: { width: "100%" }
                  },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "mr-1",
                            attrs: {
                              loading: _vm.loading,
                              disabled:
                                !_vm.valid ||
                                _vm.loading ||
                                _vm.selectedAppType === undefined ||
                                _vm.selectedImage === undefined,
                              color: "secondary"
                            },
                            on: {
                              click: function($event) {
                                return _vm.addApp()
                              }
                            }
                          },
                          [
                            _c("v-icon", { attrs: { small: "" } }, [
                              _vm._v("add")
                            ]),
                            _vm._v("Add application ")
                          ],
                          1
                        ),
                        _vm.createSpaceAndApp.operationSelected === true
                          ? _c(
                              "v-btn",
                              {
                                attrs: {
                                  disabled: _vm.loading,
                                  outlined: "",
                                  text: "",
                                  color: "secondary",
                                  exact: ""
                                },
                                on: { click: _vm.skipAppCreation }
                              },
                              [_vm._v("skip this step")]
                            )
                          : _vm._e(),
                        _vm.createSpaceAndApp.operationSelected === false
                          ? _c(
                              "v-btn",
                              {
                                attrs: {
                                  to: {
                                    name: "snapshot-applications",
                                    params: {
                                      oid: _vm.$route.params.oid,
                                      sid: _vm.$route.params.sid,
                                      iid: _vm.$route.params.iid,
                                      snid: _vm.$route.params.snid
                                    }
                                  },
                                  disabled: _vm.loading,
                                  outlined: "",
                                  text: "",
                                  color: "secondary",
                                  exact: ""
                                }
                              },
                              [_vm._v("cancel")]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm.error === true
                      ? _c(
                          "div",
                          { staticClass: "mt-5" },
                          [
                            _c(
                              "v-alert",
                              {
                                attrs: {
                                  color: "error",
                                  icon: "warning",
                                  text: ""
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "d-flex flex-column" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "font-weight-bold" },
                                      [_vm._v("Application creation error")]
                                    ),
                                    _vm._v(" " + _vm._s(_vm.errorContent) + " ")
                                  ]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                )
              ])
            ],
            1
          )
        : _vm.applicationImageFetching === true
        ? _c(
            "div",
            { staticClass: "applications-fetching" },
            [
              _c(
                "v-row",
                _vm._l(6, function(n) {
                  return _c(
                    "v-col",
                    { key: n, attrs: { cols: "12", md: "4" } },
                    [
                      _c("v-skeleton-loader", {
                        staticClass: "mx-auto",
                        attrs: { type: "list-item-avatar-three-line" }
                      })
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        : _vm.applicationImageFetching === false && _vm.imageList.length === 0
        ? _c(
            "div",
            { staticClass: "empty-applications-warning" },
            [
              _c(
                "v-alert",
                {
                  staticClass: "mt-3",
                  attrs: {
                    value: true,
                    type: "warning",
                    prominent: "",
                    text: ""
                  }
                },
                [
                  _c("div", { staticClass: "d-flex flex-column" }, [
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v("No applications available.")
                    ]),
                    _vm._v(
                      " Try refreshing the page, and if the problem persists contact the space administrator. "
                    )
                  ])
                ]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }