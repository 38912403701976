<template>
    <v-card flat>
        <v-card-title>
            <span class="primary--text font-weight-bold">Add New Application</span>
        </v-card-title>
        <div v-if="imageList.length && applicationImageFetching === false" class="applications-available">
            <v-card-text>
                <v-form ref="form" v-model="valid">
                    <h4 class="mt-4 primary--text">Select an application</h4>
                    <v-list dense>
                        <v-list-item-group min-width="100" v-model="selectedAppType" color="secondary">
                            <v-row>
                                <v-col cols="4" class="mb-1" sm="12" md="6" lg="4" v-for="(app, index) in availableApps" :key="index">
                                    <v-list-item style="height:80px">
                                        <v-img :src="getAppLink(app.short_name)" class="mr-4" max-width="50" min-width="50"></v-img>
                                        <v-list-item-content>
                                            <span class="font-weight-bold title primary--text">{{ app.name }}</span>
                                            <span class="primary--text">{{ app.description }}</span>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </v-list-item-group>
                    </v-list>
                    <v-alert v-if="showAppStartTimeWarning === true" class prominent type="info" text>
                        <div class="d-flex flex-column">
                            <span class="font-weight-bold">App initial startup time</span>
                            Please note that the app you have selected might take longer when started for the first time. Subsequent starts will be faster.
                        </div>
                    </v-alert>
                    <h4 class="mt-4 primary--text">Select a version for your application</h4>
                    <v-select
                        :items="appVersions"
                        item-value="imid"
                        item-text="description"
                        label="Click here to select an application"
                        v-model="selectedImage"
                        required
                        :rules="[rules.itemSelected]"
                    ></v-select>
                    <h4 class="mt-4 primary--text">Name of the application</h4>
                    <v-text-field v-model="appName" required :rules="[rules.nonEmpty]">
                        <template v-slot:label>Provide an application name here</template>
                    </v-text-field>
                    <v-checkbox v-model="addDescription" label="Add application description"></v-checkbox>
                    <div v-if="addDescription">
                        <h4 class="mt-4">Description of the application</h4>
                        <v-textarea
                            v-model="appDescription"
                            label="Provide a description of your application"
                            rows="4"
                            auto-grow
                            required
                            :rules="[rules.nonEmpty]"
                        ></v-textarea>
                    </div>
                </v-form>
            </v-card-text>

            <v-card-actions class="mt-4">
                <div style="width:100%" class="d-flex align-center flex-column justify-center">
                    <div>
                        <v-btn
                            class="mr-1"
                            :loading="loading"
                            @click="addApp()"
                            :disabled="!valid || loading || selectedAppType === undefined || selectedImage === undefined"
                            color="secondary"
                        >
                            <v-icon small>add</v-icon>Add application
                        </v-btn>
                        <v-btn
                            v-if="createSpaceAndApp.operationSelected === true"
                            @click="skipAppCreation"
                            :disabled="loading"
                            outlined
                            text
                            color="secondary"
                            exact
                            >skip this step</v-btn
                        >
                        <v-btn
                            v-if="createSpaceAndApp.operationSelected === false"
                            :to="{
                                name: 'snapshot-applications',
                                params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: $route.params.snid }
                            }"
                            :disabled="loading"
                            outlined
                            text
                            color="secondary"
                            exact
                            >cancel</v-btn
                        >
                    </div>
                    <div v-if="error === true" class="mt-5">
                        <v-alert color="error" icon="warning" text>
                            <div class="d-flex flex-column">
                                <span class="font-weight-bold">Application creation error</span>
                                {{ errorContent }}
                            </div>
                        </v-alert>
                    </div>
                </div>
            </v-card-actions>
        </div>
        <div v-else-if="applicationImageFetching === true" class="applications-fetching">
            <v-row>
                <v-col cols="12" md="4" v-for="n in 6" :key="n">
                    <v-skeleton-loader type="list-item-avatar-three-line" class="mx-auto"></v-skeleton-loader>
                </v-col>
            </v-row>
        </div>
        <div v-else-if="applicationImageFetching === false && imageList.length === 0" class="empty-applications-warning">
            <v-alert :value="true" type="warning" prominent class="mt-3" text>
                <div class="d-flex flex-column">
                    <span class="font-weight-bold">No applications available.</span>
                    Try refreshing the page, and if the problem persists contact the space administrator.
                </div>
            </v-alert>
        </div>
    </v-card>
</template>

<script>
import { mapState } from 'vuex'
import { appTypeAndImageLink } from '@/mixins/appTypeAndImage'
import { sortArray } from '@/utils'

export default {
    mixins: [appTypeAndImageLink],
    data() {
        return {
            appName: '',
            appDescription: '',
            error: false,
            errorContent: 'An error has occurred',
            inputError: false,
            noAppName: false,
            noAppDescription: false,
            noAppSelected: false,
            addDescription: false,
            appTypeMetaData: [
                {
                    name: 'RStudio',
                    short_name: 'rstudio',
                    description: 'An integrated development environment for R'
                },
                {
                    name: 'VS Code',
                    short_name: 'vscode',
                    description: 'A code editor optimized for building modern cloud applications'
                },
                {
                    name: 'Spyder',
                    short_name: 'spyder',
                    description: 'A scientific environment written in Python, for Python'
                },
                {
                    name: 'JupyterLab',
                    short_name: 'jupyterlab',
                    description: 'Interactive development environment for Jupyter notebooks'
                },
                {
                    name: 'GNU Octave',
                    short_name: 'octave',
                    description: 'An environment for technical and numerical computing'
                },
                {
                    name: 'Matlab',
                    short_name: 'matlab',
                    description: 'An environment for technical and numerical computing'
                },
                {
                    name: 'SAS',
                    short_name: 'sas',
                    description: 'Statistical software used for data analysis and visualization'
                },
                {
                    name: 'Stata',
                    short_name: 'stata',
                    description: 'Statistical software for data analysis and econometrics'
                },
                {
                    name: 'IBM SPSS Statistics',
                    short_name: 'spss',
                    description: 'Software package used for interactive, or batched, statistical analysis'
                },
                {
                    name: 'Other applications',
                    short_name: 'other',
                    description: ' '
                }
            ],
            allAppNames: {
                JUPYTERLAB: 'jupyterlab',
                VSCODE: 'vscode',
                MATLAB: 'matlab',
                OCTAVE: 'octave',
                SPSS: 'spss',
                RSTUDIO: 'rstudio',
                SAS: 'sas',
                STATA: 'stata',
                SPYDER: 'spyder',
                OTHER_APPLICATION: 'other'
            },
            rules: {
                nonEmpty: p => p.length > 0 || 'Required field.',
                itemSelected: v => !!v || 'Item is required'
            },
            selectedImage: undefined,
            selectedAppType: 0,
            loading: false,
            valid: false
        }
    },
    computed: {
        appVersions: function() {
            if (this.selectedAppType !== undefined && this.availableApps.length > 0) {
                const selectedApp = this.availableApps[this.selectedAppType].short_name
                const filteredAppData = this.imageList.filter(image => this.getAppType(image.app_type) === selectedApp)
                const sortedAppData = sortArray(filteredAppData, 'imid', 'descending', false)
                return sortedAppData
            } else {
                return []
            }
        },
        availableApps: function() {
            return this.$data.appTypeMetaData.filter(app => this.appExists(app.short_name) === true)
        },
        showAppStartTimeWarning: function() {
            if (
                this.$data.selectedAppType &&
                (this.$data.appTypeMetaData[this.$data.selectedAppType].short_name === this.$data.allAppNames.JUPYTERLAB ||
                    this.$data.appTypeMetaData[this.$data.selectedAppType].short_name === this.$data.allAppNames.SPYDER) &&
                this.$data.selectedImage &&
                // new apps use overlay to handle, so no copy of conda envs
                parseInt(this.$data.selectedImage) <= 34
            ) {
                return true
            }
            return false
        },
        ...mapState('snapshotStore', ['applications', 'imageList', 'applicationImageFetching']),
        ...mapState('homeStore', ['createSpaceAndApp'])
    },
    methods: {
        appExists: function(appName) {
            const filteredAppData = this.imageList.filter(image => this.getAppType(image.app_type) === appName)
            if (filteredAppData.length) {
                return true
            } else {
                return false
            }
        },
        skipAppCreation: function() {
            this.$store.dispatch('homeStore/updateCreateSpaceAndApp', { stepVal: 3, skipAppCreationVal: true })
            this.$router.push({
                name: 'snapshot-add-files-hint',
                params: {
                    oid: this.$route.params.oid,
                    sid: this.$route.params.sid,
                    iid: this.$route.params.iid,
                    snid: this.$route.params.snid
                }
            })
        },
        addApp: function() {
            this.$data.error = false
            this.$data.noAppName = false
            this.$data.noAppDescription = false
            if (this.$data.appName === '') {
                this.$data.noAppName = true
            }
            if (!this.$data.selectedImage) {
                this.$data.noAppSelected = true
            }
            if (!this.$data.noAppName && !this.$data.noAppSelected) {
                this.$data.loading = true
                const apiURL = '/snapshots/' + this.$route.params.snid + '/applications'
                var postBody = {
                    long_id: this.$data.appName,
                    short_id: window.crypto.getRandomValues(new Uint32Array(1))[0].toString(),
                    imid: this.$data.selectedImage,
                    pars: '{}'
                }
                if (this.$data.addDescription) {
                    postBody.description = this.$data.appDescription
                } else {
                    const appDesc = this.appVersions.filter(app => app.imid === this.$data.selectedImage)
                    postBody.description = appDesc[0].description
                }
                this.$axios
                    .post(apiURL, postBody, { timeout: 300000 })
                    .then(response => {
                        this.$store.dispatch('showSnackBar', {
                            snackBarText: 'Application created successfully',
                            snackBarTimeout: 5000,
                            snackBarIcon: 'check_circle'
                        })
                        if (this.createSpaceAndApp.operationSelected) {
                            this.$store.dispatch('appStore/fetchAllUserApps', { iid: this.$route.params.iid, startFirstApp: false })
                            this.$store.dispatch('snapshotStore/fetchApplications', this.$route.params.snid)
                            this.$store.dispatch('homeStore/updateCreateSpaceAndApp', { stepVal: 3 })
                            this.$router.push({
                                name: 'snapshot-add-files-hint',
                                params: {
                                    oid: this.$route.params.oid,
                                    sid: this.$route.params.sid,
                                    iid: this.$route.params.iid,
                                    snid: this.$route.params.snid
                                }
                            })
                        } else {
                            this.$store.dispatch('appStore/fetchAllUserApps', { iid: this.$route.params.iid, startFirstApp: false })
                            this.$router.push({
                                name: 'snapshot-applications',
                                params: {
                                    oid: this.$route.params.oid,
                                    sid: this.$route.params.sid,
                                    iid: this.$route.params.iid,
                                    snid: this.$route.params.snid
                                }
                            })
                        }
                    })
                    .catch(error => {
                        this.$data.error = true
                        if (
                            error.response &&
                            error.response.data &&
                            error.response.data.reason &&
                            error.response.data.reason[0].includes('duplicate key value violates unique constraint')
                        ) {
                            this.$data.errorContent = 'Another application uses the short name you chose for this application, please choose another name'
                        }
                    })
                    .finally(() => {
                        this.$data.loading = false
                    })
            }
        }
    },
    mounted() {
        this.$store.dispatch('snapshotStore/fetchCurrentImages', this.$route.params.sid)
    },
    watch: {
        appVersions: function(nextVal) {
            if (!nextVal.length) {
                this.$data.selectedImage = undefined
            } else {
                this.$data.selectedImage = nextVal[0].imid
            }
        }
    },
    beforeDestroy() {
        if (this.createSpaceAndApp.operationSelected === true && this.createSpaceAndApp.step !== 3) {
            this.$store.dispatch('homeStore/resetCreateSpaceAndApp')
        }
    }
}
</script>
